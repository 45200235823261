import React from 'react';
import Header from './components/layout/Header';
// import Footer from './components/layout/Footer';
import Home from './components/pages/Home';

import NoteState from './context/note/NoteState';
import AlertState from './context/alert/AlertState';

function App() {
    return (
        <div>
            <NoteState>
                <AlertState>
                    <Header />
                    <Home />
                    {/* <Footer /> */}
                </AlertState>
            </NoteState>
        </div>
    );
}

export default App;
