import React, { useReducer } from 'react';
import axios from 'axios';
import NoteContext from './noteContext';
import NoteReducer from './noteReducer';

import {
    ADD_NOTE,
    GET_NOTES,
    UPDATE_NOTE,
    DELETE_NOTE,
    NOTE_ERROR,
    CLEAR_NOTE
} from '../types';

const NoteState = props => {
    const initialState = {
        notes: null,
        error: null,
        loading: true
    };

    const [state, dispatch] = useReducer(NoteReducer, initialState);

    // Get Notes
    const getNotes = async user => {
        try {
            const res = await axios.get(`/api/notes/${user}`);

            // console.log(res)

            dispatch({
                type: GET_NOTES,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: NOTE_ERROR,
                payload: err.response.msg
            });
        }
    };

    // Add Notes
    const addNotes = async (app, event, user, note) => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const res = await axios.post(
                `/api/notes?app=${app}&event=${event}&user=${user}`,
                note,
                config
            );

            dispatch({
                type: ADD_NOTE,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: NOTE_ERROR,
                payload: err.response.msg
            });
        }
    };

    // Delete Note
    const deleteNote = async (user, id) => {
        try {
            const res = await axios.delete(`/api/notes/${user}/${id}`);

            dispatch({
                type: DELETE_NOTE,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: NOTE_ERROR,
                payload: err.response.data.msg
            });
        }
    };

    // Update Note
    const updateNote = async (user, id, note) => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        try {
            const res = await axios.patch(
                `/api/notes/${user}/${id}`,
                note,
                config
            );

            dispatch({
                type: UPDATE_NOTE,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: NOTE_ERROR,
                payload: err.response.data.msg
            });
        }
    };

    // Clear Note
    const clearNotes = () => {
        dispatch({ type: CLEAR_NOTE });
    };

    // // Sort Note
    // const sortNotes = async () => {
    //     dispatch({ type: SORT_NOTE });
    // };

    return (
        <NoteContext.Provider
            value={{
                notes: state.notes,
                getNotes,
                addNotes,
                deleteNote,
                updateNote,
                clearNotes,
                error: state.error
            }}
        >
            {props.children}
        </NoteContext.Provider>
    );
};

export default NoteState;
