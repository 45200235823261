import React from 'react';
import Notes from '../notes/Notes';
import NotesForm from '../notes/NotesForm';
import Alerts from '../common/Alert';

const Home = () => {
    return (
        <div className='wrapper'>
            <NotesForm />
            <Alerts />
            <Notes />
        </div>
    );
};

export default Home;
