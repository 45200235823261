import React, { Fragment, useContext, useState, useEffect } from 'react';
import getUrlParameter from '../../utils/getParams';
import Spinner from '../common/Spinner';

import NoteContext from '../../context/note/noteContext';
import AlertContext from '../../context/alert/alertContext';

const NotesForm = () => {
    const noteContext = useContext(NoteContext);
    const alertContext = useContext(AlertContext);

    const { setAlert } = alertContext;
    const { addNotes } = noteContext;

    const query = {
        app: getUrlParameter('app'),
        event: getUrlParameter('event'),
        user: getUrlParameter('user')
    };

    useEffect(() => {
        if (query.app === '' || query.event === '' || query.user === '') {
            setLoading(true);
        }
    }, [query]);

    const [loading, setLoading] = useState(false);

    const initialState = {
        title: '',
        note: ''
    };

    const [notes, setNotes] = useState(initialState);

    const { title, note } = notes;

    const onChange = e => {
        setNotes({ ...notes, [e.target.name]: e.target.value });
    };

    const onSubmit = async e => {
        e.preventDefault();
        await addNotes(query.app, query.event, query.user, notes);
        setNotes(initialState);
        setAlert('Note Added', 'success', 1200);
    };

    return (
        <Fragment>
            <div className='note-container'>
                {!loading ? (
                    <form className='form' onSubmit={onSubmit}>
                        <input
                            type='text'
                            placeholder='Title'
                            name='title'
                            value={title}
                            onChange={onChange}
                            required
                        />
                        <br></br>
                        <textarea
                            type='text'
                            placeholder='Type your note'
                            name='note'
                            value={note}
                            onChange={onChange}
                            required
                        />
                        <input
                            className='submit'
                            type='submit'
                            value='Save Note'
                        />
                    </form>
                ) : (
                    <Spinner />
                )}
            </div>
        </Fragment>
    );
};

export default NotesForm;
