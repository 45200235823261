/**
 * @description - Extracts a variables from query string
 * @param name - query string variable
 */
function getUrlParameter(name) {
    name = name.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default getUrlParameter;
