import React from 'react';

const Modal = ({ confirm, status, modal }) => {
    return (
        <div className='modal' style={{ display: status ? 'flex' : '' }}>
            <div className='modal-dialog' role='document'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>Confirm Delete</h5>
                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={() => modal(false)}
                        >
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <p>Are you sure you would like to delete this note?</p>
                    </div>
                    <div className='modal-footer'>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={confirm}
                        >
                            Delete
                        </button>
                        <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => modal(false)}
                            data-dismiss='modal'
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
