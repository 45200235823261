import {
    ADD_NOTE,
    GET_NOTES,
    UPDATE_NOTE,
    DELETE_NOTE,
    NOTE_ERROR,
    CLEAR_NOTE
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case ADD_NOTE:
            return {
                ...state,
                notes: action.payload,
                loading: false,
                error: null
            };
        case GET_NOTES:
            return {
                ...state,
                notes: action.payload,
                loading: false
            };
        // case GET_NOTE:
        case DELETE_NOTE:
            return {
                ...state,
                notes: action.payload,
                error: null
            };
        case UPDATE_NOTE:
            return {
                ...state,
                notes: action.payload,
                error: null
            };
        // case SORT_NOTE:
        //     return {
        //         ...state,
        //         notes: [...state.notes, state.notes.notes.sort((a, b) => b.date - a.date)]
        //     };
        case CLEAR_NOTE:
            return {
                ...state,
                notes: null,
                error: null
            };
        case NOTE_ERROR:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};
