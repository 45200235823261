import React, { useContext, useRef, useState, Fragment } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Accordion from 'react-bootstrap/Accordion';
import Modal from '../common/Modal';
import { Card } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import NotesPrint from '../notes/NotesPrint';
import NoteContext from '../../context/note/noteContext';
import AlertContext from '../../context/alert/alertContext';

const NoteItem = ({ noteData, user }) => {
    const alertContext = useContext(AlertContext);

    const { setAlert } = alertContext;

    const { date, _id, title, note } = noteData;

    const noteContext = useContext(NoteContext);

    const { deleteNote, updateNote } = noteContext;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const initialState = {
        title: title,
        note: note
    };

    const [formData, setFormData] = useState(initialState);

    const [edit, setEdit] = useState(false);

    const onDelete = async e => {
        e.preventDefault();
        await deleteNote(user, _id);
        setAlert('Note Deleted', 'success', 1200);
        setShow(false);
    };
    const onUpdate = async e => {
        e.preventDefault();
        await updateNote(user, _id, formData);
        setEdit(false);
        window.scrollTo(0, 0);
        setAlert('Note Updated', 'success', 1200);
    };

    const onEdit = () => {
        setEdit(true);
    };

    const [show, setShow] = useState(false);

    const modalHandler = modalOpen => {
        setShow(modalOpen);
    };

    const notesRef = useRef();
    return (
        <Fragment>
            <Accordion defaultActiveKey='1'>
                <Card>
                    <div className='updateForm'>
                        <Accordion.Toggle as={Card.Header} eventKey='0'>
                            {!edit ? (
                                <div>
                                    <h2 className='noteTitle'>{title}</h2>
                                    <h2 className='date'>
                                        <Moment date={date} />
                                    </h2>
                                </div>
                            ) : (
                                ''
                            )}
                        </Accordion.Toggle>

                        {edit ? (
                            <div className='inputWrapper'>
                                <input
                                    type='text'
                                    placeholder='Title'
                                    name='title'
                                    value={formData.title}
                                    onChange={onChange}
                                    required
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        <div className='noteButtons'>
                            <button
                                className='delete'
                                onClick={() => modalHandler(true)}
                            ></button>
                            <ReactToPrint
                                trigger={() => (
                                    <button
                                        className='print'
                                        type='button'
                                    ></button>
                                )}
                                content={() => notesRef.current}
                            />
                        </div>
                        <div className='hide'>
                            <NotesPrint ref={notesRef} data={noteData} />
                        </div>

                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                {!edit ? (
                                    <div className='editButtons'>
                                        <button
                                            className='edit'
                                            onClick={onEdit}
                                            type='button'
                                        ></button>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {!edit ? (
                                    <p className='noteBody'>{formData.note}</p>
                                ) : (
                                    <textarea
                                        type='text'
                                        placeholder='Type your note'
                                        name='note'
                                        value={formData.note}
                                        onChange={onChange}
                                        required
                                    />
                                )}
                                {!edit ? (
                                    ''
                                ) : (
                                    <div className='updateButtons'>
                                        <button
                                            className='update'
                                            onClick={onUpdate}
                                        >
                                            Update
                                        </button>
                                    </div>
                                )}
                            </Card.Body>
                        </Accordion.Collapse>
                    </div>
                </Card>
            </Accordion>
            <Modal confirm={onDelete} status={show} modal={modalHandler} />
        </Fragment>
    );
};

export default NoteItem;
