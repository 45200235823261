import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class NotesPrint extends React.Component {
    render() {
        return (
            <div className='printStyle'>
                <h1>{this.props.data.title}</h1>
                <p>
                    <Moment date={this.props.data.date} />
                </p>
                <br></br>
                <p>{this.props.data.note}</p>
            </div>
        );
    }
}

export default NotesPrint;
