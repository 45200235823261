import React, { useEffect, useContext, Fragment, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NoteItem from '../notes/NoteItem';
import Spinner from '../common/Spinner';
import getUrlParameter from '../../utils/getParams';
import NoteContext from '../../context/note/noteContext';

const Notes = () => {
    const noteContext = useContext(NoteContext);

    const { getNotes, notes, loading } = noteContext;

    useEffect(() => {
        const query = {
            app: getUrlParameter('app'),
            event: getUrlParameter('event'),
            user: getUrlParameter('user')
        };
        // if (notes !== null) {
        //     if (!notes.notes.length) clearNotes();
        // }
        // const fetchData = async () => {
        //     await getNotes(query.user);
        // }

        if (query.app === '' || query.event === '' || query.user === '') {
            setParams(true);
        } else {
            getNotes(query.user);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (notes !== null) {
            setSortedNotes(
                notes.notes.sort((a, b) => (a.date < b.date ? 1 : -1))
            );
            // console.log(notes)
        }
    }, [notes]);

    const initialNotes = [];

    const [params, setParams] = useState(false);

    const [sortedNotes, setSortedNotes] = useState(initialNotes);

    return (
        <Fragment>
            <div className='list-container'>
                {notes !== null && !loading ? (
                    <Fragment>
                        {sortedNotes.length ? (
                            <TransitionGroup>
                                {sortedNotes.map((note, key) => (
                                    <CSSTransition
                                        key={note._id}
                                        timeout={500}
                                        classNames='item'
                                    >
                                        <ul className='notes-list'>
                                            <NoteItem
                                                noteData={note}
                                                user={notes.grip_thing_id}
                                                key={key}
                                            />
                                        </ul>
                                    </CSSTransition>
                                ))}
                            </TransitionGroup>
                        ) : (
                            <h2 className='nonotes'> No notes </h2>
                        )}
                    </Fragment>
                ) : !params ? (
                    <h2 className='nonotes'> No notes </h2>
                ) : (
                    <Spinner />
                )}
            </div>
        </Fragment>
    );
};

export default Notes;
